import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'


import React, { useEffect } from 'react';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";
import { HowTo } from './pages/howto';
import { HomePage } from './pages/homepage';
import { Rules } from './pages/rules';
import { SudokuSolver } from './pages/sudokusolver';
import { AboutUs } from './pages/aboutus';
import { Privacy } from './pages/privacy';
import { Cookies } from './pages/cookies';
import { Contact } from './pages/contact';
import { HomePageTr } from './pages/homepagetr';
import { HomePageEn } from './pages/homepageen';


function App() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        ReactGA.initialize("G-M1CT9FM35Q");
    }, [])


    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    return (<div className="App"
        onContextMenu={onContextMenu}
        tabIndex={0}> {/* HEADER START */}
        <div className="container">
            <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                <Link to={t("url.homepage")} className={"d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"}>
                    <img className="logo" src='../sudokukilogo.png' />
                </Link>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <Link to={t("url.howto")} className={"nav-link"}>
                            {t("menu.howto")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={t("url.sudokurules")} className={"nav-link"}>
                            {t("menu.rules")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={t("url.sudokusolver")} className={"nav-link"}>
                            {t("menu.solver")}
                        </Link>
                    </li>

                    <div className="dropdown">
                        <button className="btn nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {i18n.resolvedLanguage == "en" && <React.Fragment><img src="../english.png" height="20px" className="mr-2"></img> EN</React.Fragment>}
                            {i18n.resolvedLanguage == "tr" && <React.Fragment><img src="../turkish.png" height="20px" className="mr-2"></img> TR</React.Fragment>}
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <Link to={"/"} className={"dropdown-item nav-link"}>
                                    <img src="../english.png" height="20px" className="mr-2" ></img> English
                                </Link>
                            </li>
                            <li>
                                <Link to={"/tr"} className={"dropdown-item nav-link"}>
                                    <img src="../turkish.png" height="20px" className="mr-2" ></img> Türkçe
                                </Link>
                            </li>
                        </ul>
                    </div>
                </ul>
            </header>
        </div>
        {/* HEADER END */}

        {/* BODY START */}
        <React.Fragment>
            <Routes>
                <Route path={"/"} element={<HomePageEn></HomePageEn>} />
                <Route path={"/tr"} element={<HomePageTr></HomePageTr>} />
                <Route path={"/how-to-play-sudoku"} element={<HowTo></HowTo>} />
                <Route path={"/tr/nasil-oynanir"} element={<HowTo></HowTo>} />
                <Route path={"/sudoku-rules"} element={<Rules></Rules>} />
                <Route path={"/tr/sudoku-kurallari"} element={<Rules></Rules>} />
                <Route path={"/sudoku-solver"} element={<SudokuSolver></SudokuSolver>} />
                <Route path={"/tr/sudoku-cozucu"} element={<SudokuSolver></SudokuSolver>} />
                <Route path={"/about-us"} element={<AboutUs></AboutUs>} />
                <Route path={"/tr/hakkimizda"} element={<AboutUs></AboutUs>} />
                <Route path={"/privacy"} element={<Privacy></Privacy>} />
                <Route path={"/tr/gizlilik"} element={<Privacy></Privacy>} />
                <Route path={"/cookies"} element={<Cookies></Cookies>} />
                <Route path={"/tr/cerezler"} element={<Cookies></Cookies>} />
                <Route path={"/contact-us"} element={<Contact></Contact>} />
                <Route path={"/tr/iletisim"} element={<Contact></Contact>} />
            </Routes>
        </React.Fragment>
        {/* BODY END */}

        {/* FOOOTER START */}
        <div className='container'>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <p className="col-md-4 text-body-secondary text-left">© 2024 Sudokuki.com</p>

                <a href={t("url.homepage")} className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                    <img className="logo" src="../sudokukilogo.png" />
                </a>

                <ul className="nav col-md-4 justify-content-end">
                    <li className="nav-item"><Link to={t("url.aboutus")} className={"nav-link px-2 text-body-secondary"}>{t("menu.aboutus")}</Link></li>
                    <li className="nav-item"><Link to={t("url.privacy")} className={"nav-link px-2 text-body-secondary"}>{t("menu.privacy")}</Link></li>
                    <li className="nav-item"><Link to={t("url.cookies")} className={"nav-link px-2 text-body-secondary"}>{t("menu.cookies")}</Link></li>
                    <li className="nav-item"><Link to={t("url.contact")} className={"nav-link px-2 text-body-secondary"}>{t("menu.contactus")}</Link></li>
                </ul>
            </footer>
        </div>
        {/* FOOOTER END */}
    </div>);
}
export default App;