import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CIcon from '@coreui/icons-react';
import {
    cilMediaPause,
    cilReload,
    cilHighlighter,
    cilNotes,
    cilLightbulb,
    cilMediaPlay,
    cilCheckAlt,
    cilX
} from '@coreui/icons';
import { difficulty, maxErrorCount } from '../constants/sudoku';
import { Hardness } from "../types/types";
import { Colors } from "../constants/colors";
import { Sudoku } from "../helpers/sudoku";
import { ISudokuCell } from "../types/interfaces";
import { shuffleArray } from "../helpers/array";
import { CustomHelmet } from "../components/customhelmet";
import { useLocation } from "react-router-dom";

export function HomePage() {
    const { t } = useTranslation();

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const gridSize = useRef<number>(9);
    const selectedX = useRef<number>(0);
    const selectedY = useRef<number>(0);
    const lastSelectedArray = useRef<number[][]>([]);
    const isSelected = useRef<boolean>(false);
    const initialized = useRef<boolean>(false);
    const sudoku = useRef<Sudoku>(new Sudoku());
    const [hardness, setHardness] = useState<Hardness>(1);
    const [errorCount, setErrorCount] = useState<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const [points, setPoints] = useState<number[][]>();
    const [timerPaused, setTimerPaused] = useState<boolean>(false);
    const [notesOn, setNotesOn] = useState<boolean>(false);
    const [hintCount, setHintCount] = useState<number>(3);
    const [gameFinished, setGameFinished] = useState<boolean>(false);

    const clearGrid = () => {
        const ctx = canvasRef.current!.getContext('2d')!;
        ctx.clearRect(0, 0, 1000, 1000);
    }
    const drawGrid = () => {
        const ctx = canvasRef.current!.getContext('2d')!;
        if (isSelected.current) {
            const selectedVal = sudoku
                .current
                .board[selectedY.current][selectedX.current]
                .Value;
            const smallGridX = Math.floor(selectedX.current / Math.sqrt(gridSize.current));
            const smallGridY = Math.floor(selectedY.current / Math.sqrt(gridSize.current));
            for (let x = 0; x < gridSize.current; x++) {
                for (let y = 0; y < gridSize.current; y++) {
                    if (x == selectedX.current || y == selectedY.current || (smallGridX == Math.floor(x / Math.sqrt(gridSize.current)) && smallGridY == Math.floor(y / Math.sqrt(gridSize.current)))) {
                        ctx.fillStyle = Colors.secondarySelection;
                        ctx.fillRect(x * (1000 / gridSize.current), y * (1000 / gridSize.current), 1000 / gridSize.current, 1000 / gridSize.current);
                    }
                    if (selectedVal != "0" && selectedVal == sudoku
                        .current
                        .board[y][x]
                        .Value) {
                        ctx.fillStyle = "#C3D7EA";
                        ctx.fillRect(x * (1000 / gridSize.current), y * (1000 / gridSize.current), 1000 / gridSize.current, 1000 / gridSize.current);
                    }
                }
            }
            ctx.fillStyle = Colors.primarySelection;
            ctx.fillRect(selectedX.current * (1000 / gridSize.current), selectedY.current * (1000 / gridSize.current), 1000 / gridSize.current, 1000 / gridSize.current);
        }
        ctx.strokeStyle = Colors.primaryLine;
        ctx.lineWidth = 4;
        ctx.strokeRect(3, 3, 994, 994);
        for (let index = 0; index < gridSize.current + 1; index++) {
            ctx.beginPath();
            ctx.strokeStyle = Colors.primaryLine;
            ctx.lineWidth = index % Math.sqrt(gridSize.current) == 0
                ? 5
                : 1;
            ctx.moveTo(0, (index / gridSize.current) * 1000);
            ctx.lineTo(1000, (index / gridSize.current) * 1000);
            ctx.stroke();
            ctx.beginPath();
            ctx.strokeStyle = Colors.primaryLine;
            ctx.lineWidth = index % Math.sqrt(gridSize.current) == 0
                ? 5
                : 1;
            ctx.moveTo((index / gridSize.current) * 1000, 0);
            ctx.lineTo((index / gridSize.current) * 1000, 1000);
            ctx.stroke();
        }
        for (let x = 0; x < gridSize.current; x++) {
            for (let y = 0; y < gridSize.current; y++) {
                const cell = sudoku.current.board[x][y];
                if (cell.Value != "0") {
                    ctx.font = "70pt Arial";
                    ctx.fillStyle = Colors.primaryLine;
                    if (cell.WrongValue) {
                        ctx.fillStyle = Colors.wrongValue;
                    }
                    else if (cell.Hint) {
                        ctx.fillStyle = Colors.hint;
                    }
                    ctx.fillText(cell.Value, ((1000 / gridSize.current) * y) + 30, ((1000 / gridSize.current) * x) + 90);
                }
                else if (cell.Notes.length > 0) {
                    for (let index = 0; index < cell.Notes.length; index++) {
                        ctx.font = "20pt Arial";
                        ctx.fillStyle = Colors.primaryLine;
                        const noteValueString = cell.Notes[index];
                        const noteValue = parseInt(noteValueString);
                        ctx.fillText(noteValueString, ((1000 / gridSize.current) * y) + (Math.floor((noteValue - 1) % 3) * 30) + 20,
                            ((1000 / gridSize.current) * x) + (Math.floor((noteValue - 1) / 3) * 30) + 35);
                    }

                }
            }
        }
    }

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            clearGrid();
            sudoku.current = new Sudoku();
            sudoku.current.generate(9, hardness);
            drawGrid();
            reset();
        }
    }, [])

    useEffect(() => {
        newSudoku();
    }, [hardness])

    useEffect(() => {
        if (errorCount == maxErrorCount || gameFinished) {
            setTimerPaused(true);
        }
    }, [errorCount, gameFinished])

    const newSudoku = () => {
        if (initialized.current) {
            clearGrid();
            sudoku.current.generate(9, hardness);
            drawGrid();
            reset();
        }
    }

    const reset = () => {
        const pointsTable: number[][] = [];
        for (let x = 0; x < gridSize.current; x++) {
            pointsTable.push([]);
            for (let y = 0; y < gridSize.current; y++) {
                pointsTable[x].push(0);
            }
        }
        setPoints(pointsTable);
        lastSelectedArray.current = [];
        setErrorCount(0);
        setTimer(0);
        setHintCount(3);
        setTimerPaused(false);
    };

    const calculatePoints = () => {
        let totalPoints = 0;
        if (points) {
            for (let x = 0; x < gridSize.current; x++) {
                for (let y = 0; y < gridSize.current; y++) {
                    totalPoints += points[x][y];
                }
            }
        }
        return totalPoints;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!timerPaused) {
                setTimer(timer + 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer, timerPaused]);

    const hint = () => {
        if (hintCount == 0 || errorCount == maxErrorCount || gameFinished) {
            return;
        }
        const boardCopy: ISudokuCell[][] = JSON.parse(JSON.stringify(sudoku.current.board));
        for (let x = 0; x < boardCopy.length; x++) {
            for (let y = 0; y < boardCopy[x].length; y++) {
                if (boardCopy[x][y].WrongValue) {
                    boardCopy[x][y].Value = "0";
                }
            }
        }

        let solvedBoard = sudoku.current.solveSudokuBoard(boardCopy);
        if (solvedBoard) {
            shuffleArray(solvedBoard);
            for (let index = 0; index < solvedBoard.length; index++) {
                const solvedCell = solvedBoard[index];
                const cell = sudoku.current.board[solvedCell.x][solvedCell.y];
                if (cell.Value == "0") {
                    setHintCount(hintCount - 1);
                    clearGrid();
                    cell.WrongValue = false;
                    cell.Notes = [];
                    cell.Value = solvedCell.value.toString();
                    cell.Hint = true;
                    cell.Constant = true;
                    drawGrid();
                    return;
                }
            }
        }
    }

    const onMouseDown = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        isSelected.current = true;
        clearGrid();
        const rects = canvasRef.current!.getClientRects();
        const canvasSize = rects[0].height;
        const xPosition = (
            (event as React.MouseEvent<HTMLCanvasElement>).clientX != undefined
                ? (event as React.MouseEvent<HTMLCanvasElement>).clientX
                : (event as React.TouchEvent<HTMLCanvasElement>).touches[0].clientX
        ) - rects[0].left;
        const yPosition = (
            (event as React.MouseEvent<HTMLCanvasElement>).clientY != undefined
                ? (event as React.MouseEvent<HTMLCanvasElement>).clientY
                : (event as React.TouchEvent<HTMLCanvasElement>).touches[0].clientY
        ) - rects[0].top;
        selectedX.current = Math.floor(xPosition / canvasSize * gridSize.current);
        selectedY.current = Math.floor(yPosition / canvasSize * gridSize.current);
        drawGrid();
    }

    const onKeyDown = (event: any) => {
        setKey(event.key);
        if (event.key == "ArrowDown" || event.key == "ArrowUp" || event.key == "ArrowRight" || event.key == "ArrowLeft") {
            event.preventDefault();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown, true);

        return () => {
            window.removeEventListener("keydown", onKeyDown, true);
        }
    }, [onKeyDown])

    const setKey = (key: string) => {
        if (isSelected.current == false || errorCount == maxErrorCount || gameFinished) {
            return;
        }
        const cell = sudoku.current.board[selectedY.current][selectedX.current];
        if (key == "Delete") {
            if (cell.Constant == true) {
                return;
            }
            clearGrid();
            cell.WrongValue = false;
            cell.Notes = [];
            cell.Value = "0";
            drawGrid();
        } else if (key == "1" || key == "2" || key == "3" || key == "4" || key == "5" || key == "6" || key == "7" || key == "8" || key == "9") {
            if (cell.Constant == true) {
                return;
            }
            clearGrid();
            if (notesOn) {
                if (cell.Notes.includes(key)) {
                    cell.Notes = cell.Notes.filter((note: string) => { return note != key });
                }
                else {
                    cell.Notes.push(key);
                }
            }
            else {
                cell.Value = key;
                cell.WrongValue = false;
                cell.Notes = [];
                const boardCopy: ISudokuCell[][] = JSON.parse(JSON.stringify(sudoku.current.board));
                for (let x = 0; x < boardCopy.length; x++) {
                    for (let y = 0; y < boardCopy[x].length; y++) {
                        if (boardCopy[x][y].WrongValue) {
                            boardCopy[x][y].Value = "0";
                        }
                    }
                }
                lastSelectedArray.current.push([selectedX.current, selectedY.current])
                if (!sudoku.current.checkSudokuBoard(boardCopy, selectedY.current, selectedX.current)) {
                    cell.WrongValue = true;
                    setErrorCount(errorCount + 1);
                    if (points) {
                        points[selectedY.current][selectedX.current] = 0;
                        setPoints([...points]);
                    }
                }
                else {
                    if (points) {
                        points[selectedY.current][selectedX.current] = 50;
                        setPoints([...points]);
                    }
                    let hasEmptyCell = false;
                    for (let x = 0; x < gridSize.current; x++) {
                        for (let y = 0; y < gridSize.current; y++) {
                            if (sudoku.current.board[x][y].Value == "0") {
                                hasEmptyCell = true;
                            }
                        }
                    }

                    if (hasEmptyCell == false) {
                        setGameFinished(true);
                    }
                }
            }
            drawGrid();
        } else if (key == "ArrowDown") {
            if (selectedY.current < (gridSize.current - 1)) {
                selectedY.current = selectedY.current + 1;
                clearGrid();
                drawGrid();
            }
        } else if (key == "ArrowUp") {
            if (selectedY.current > 0) {
                selectedY.current = selectedY.current - 1;
                clearGrid();
                drawGrid();
            }
        } else if (key == "ArrowRight") {
            if (selectedX.current < (gridSize.current - 1)) {
                selectedX.current = selectedX.current + 1;
                clearGrid();
                drawGrid();
            }
        } else if (key == "ArrowLeft") {
            if (selectedX.current > 0) {
                selectedX.current = selectedX.current - 1;
                clearGrid();
                drawGrid();
            }
        }
    }

    const undo = () => {
        if (errorCount == maxErrorCount || gameFinished) {
            return;
        }
        if (lastSelectedArray.current.length > 0) {
            const cell = sudoku.current.board[lastSelectedArray.current[lastSelectedArray.current.length - 1][1]][lastSelectedArray.current[lastSelectedArray.current.length - 1][0]];
            lastSelectedArray.current.pop();
            clearGrid();
            cell.WrongValue = false;
            cell.Value = "0";
            drawGrid();
        }

    }

    const getNumpad = () => {
        const numArray = [];
        for (let index = 1; index <= gridSize.current; index++) {
            numArray.push(index.toString());
        }
        return numArray.map((val: string) => {
            return <div key={val} className="numpad-item" onClick={() => { setKey(val) }} data-value={val}>{val}</div>
        })
    }

    const formatTime = (timer: number) => {
        const seconds = (timer % 60).toString().padStart(2, "0");
        const minutes = ((Math.floor(timer / 60) % 60)).toString().padStart(2, "0");
        const hours = (Math.floor(timer / (60 * 60)) % 60);
        if (hours != 0) {
            return hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;
        }
        return minutes + ":" + seconds;
    }

    return (
        <React.Fragment>
            <CustomHelmet title={t("helmet.homepage.title")}
                description={t("helmet.homepage.description")}
                keywords={t("helmet.homepage.keywords")}
                ogTitle={t("helmet.homepage.title")}
                ogDescription={t("helmet.homepage.description")}
                ogImage={t("url.helmetImage")}
            />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6 canvas-wrapper px-5'>
                        <div className='hardness-buttons'>
                            <div className="form-group">
                                <label> {
                                    t("title.hardnessTitle")
                                }</label>
                                {Object.keys(difficulty).map((item, index) => {
                                    const itemHardness: Hardness = parseInt(item) as Hardness;
                                    return <a
                                        key={index}
                                        className={
                                            "afont " + (
                                                hardness == itemHardness
                                                    ? "hardness-selected"
                                                    : ""
                                            )
                                        }
                                        onClick={
                                            () => {
                                                setHardness(itemHardness);
                                            }
                                        }> {
                                            t("title.hardness." + item)
                                        } </a>
                                })}
                            </div>
                        </div>
                        <div className="game-overlay">
                            <canvas ref={canvasRef}
                                onMouseDown={onMouseDown}
                                onTouchStart={onMouseDown}
                                className='main-canvas'
                                width={"1000"}
                                height={"1000"}>
                            </canvas>
                            {timerPaused && errorCount != maxErrorCount && !gameFinished && <div className='pause-overlay' onClick={() => {
                                setTimerPaused(!timerPaused);
                            }}>
                                <CIcon icon={cilMediaPlay}
                                    className='pause-overlay-button'
                                    size="lg" />
                                {t("title.resume")}
                            </div>}
                            {gameFinished && <div className='winner-overlay'>
                                <CIcon icon={cilCheckAlt}
                                    className='winner-overlay-button'
                                    size="lg" />

                                {t("title.congurilation")}
                            </div>}
                            {errorCount == maxErrorCount && <div className='loser-overlay'>
                                <CIcon icon={cilX}
                                    className='loser-overlay-button'
                                    size="lg" />
                                <div>
                                    {t("title.lost1")}
                                    <br></br>
                                    {t("title.lost2")}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className='col-sm-6 canvas-wrapper left-bar'>
                        <div className='hardness-buttons d-flex justify-content-between w-50'>
                            <div className='me-3'> {t("title.errors")}<span className='hardness-selected'>{errorCount} / 3</span>
                            </div>
                            <div className='me-3'> {t("title.points")} <span className='hardness-selected'>{calculatePoints()}</span>
                            </div>
                            <div className='me-2' >
                                <span className='hardness-selected'>{formatTime(timer)}</span>
                                <span onClick={() => {
                                    if (errorCount == maxErrorCount || gameFinished) {
                                        return;
                                    }
                                    setTimerPaused(!timerPaused);
                                }} className='timer-pause'><CIcon icon={timerPaused ? cilMediaPlay : cilMediaPause}
                                    className='mini-icon'
                                    size="sm" /></span>
                            </div>
                        </div>
                        <div>
                            <div className="game-controls w-50">
                                <div className="game-controls-item-wrap" onClick={() => { undo() }}>
                                    <div className="game-controls-item game-controls-undo"><CIcon icon={cilReload}
                                        className='game-control-icon'
                                        size="sm" /></div>
                                    <div className="game-controls-label">
                                        {t("title.undo")}
                                    </div>
                                </div>
                                <div className="game-controls-item-wrap" onClick={() => { setKey("Delete") }}>
                                    <div className="game-controls-item game-controls-erase"><CIcon icon={cilHighlighter}
                                        className='game-control-icon'
                                        size="sm" /></div>
                                    <div className="game-controls-label">
                                        {t("title.remove")}
                                    </div>
                                </div>
                                <div className="game-controls-item-wrap" onClick={() => {
                                    if (errorCount == maxErrorCount || gameFinished) {
                                        return;
                                    }
                                    setNotesOn(!notesOn)
                                }}>
                                    <div className="game-controls-item game-controls-pencil"><CIcon icon={cilNotes}
                                        className='game-control-icon'
                                        size="sm" />
                                        {!notesOn && <span className="game-controls-item-after">
                                            {t("title.off")}
                                        </span>}
                                        {notesOn && <span className="game-controls-item-after blue-bg">
                                            {t("title.on")}
                                        </span>}
                                    </div>
                                    <div className="game-controls-label">
                                        {t("title.notes")}
                                    </div>
                                </div>
                                <div className="game-controls-item-wrap" onClick={() => { hint() }}>
                                    <div className="game-controls-item game-controls-hint"><CIcon icon={cilLightbulb}
                                        className='game-control-icon'
                                        size="sm" /><div className="game-controls-hint-label active">{hintCount}</div>
                                    </div>
                                    <div className="game-controls-label">
                                        {t("title.hint")}
                                    </div>
                                </div>
                            </div>
                            <div className="numpad-wrapper w-50 mt-5">
                                <div id="numpad" className="numpad">
                                    {getNumpad()}
                                </div>
                            </div>
                            <div className='button main-game-button mt-4 w-50' onClick={() => {
                                newSudoku();
                            }}> {t("title.newgame")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='w-75 text-start mt-5 px-5'>
                        <h1>{t("pages.homepage.header1")}</h1>
                        <p>
                            {t("pages.homepage.p1")}
                        </p>
                        <p>
                            {t("pages.homepage.p2")}
                        </p>
                        <h2>{t("pages.homepage.header2")}</h2>
                        <p>
                            {t("pages.homepage.p3")}
                        </p>
                        <h2>{t("pages.homepage.header3")}</h2>
                        <p>
                            {t("pages.homepage.p4")}
                        </p>
                        <p>
                            {t("pages.homepage.p5")}
                        </p>
                        <h3> {t("pages.homepage.header4")}</h3>
                        <p>
                            {t("pages.homepage.p6")}
                        </p>
                        <h3> {t("pages.homepage.header5")}</h3>
                        <p>
                            {t("pages.homepage.p7")}
                        </p>
                        <p>
                            {t("pages.homepage.p8")}
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment >);
}